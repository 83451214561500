<template>
	<v-container class="my-2">
		<v-layout row wrap v-if="user.user_role==1 || user.user_role==2">
			<!-- create  3 btns each one leads to a different page edit customer edit order edit order_items-->
			<v-flex xs12 lg12 xl12 md12 sm12 >
                <h1 class="text-center"  text-color="primary">Admin Edit </h1>
            </v-flex>
			<v-flex xs12 lg4 xl3 md3 sm4 @click="goToEditCustomer()">
				<div class="ma-4" >
					<v-card class="ma-4" color="primary" dark>
						<v-card-text>
							<h1 class="text-center pa-4">Edit Customers</h1>
						</v-card-text>
					</v-card>
				</div>
			</v-flex>
			<v-flex xs12 lg4 xl3 md3 sm4 >
				<div class="ma-4" @click="goToEditOrder()">
					<v-card class="ma-4" color="primary" dark>
						<v-card-text>
							<h1 class="text-center pa-4">Edit Orders Info</h1>
						</v-card-text>
					</v-card>

				</div>
			</v-flex>
			<v-flex xs12 lg4 xl3 md3 sm4 >
				<div class="ma-4" @click="goToEditOrderItems()">
					<v-card class="ma-4" color="primary" dark>
						<v-card-text>
							<h1 class="text-center pa-4">Edit Order Items</h1>
						</v-card-text>
					</v-card>
				</div>
			</v-flex>

		</v-layout>
        <v-layout v-else>
            <h1>this page is only allowed for the admin</h1>
        </v-layout>
	</v-container>
</template>
<script>
	// redirect each methods to that page 
	export default {
		methods: {
			goToEditCustomer() {
				this.$router.push({ name: 'CustomerEdits' })
			},
			goToEditOrder() {
				this.$router.push({ name: 'ListOrders' })
			},
			goToEditOrderItems() {
				this.$router.push({ name: 'Admin_ListOrder_items' })
			}
		},
        computed: {
            // get the user from the store
            user() {
                return this.$store.getters.user
            }
        },

	}
</script>